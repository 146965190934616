import bp from './breakpoints';
const colors = {
    blush: '#ffd7dc',
    pine: '#003333',
    light: '#fff',
    pink: '#E8308A',
};
const theme = {
    breakpoints: [bp.sm, bp.md, bp.lg, bp.xl, bp.xxl, bp.xxxl],
    fonts: {
        antiqueOlive: 'AntiqueOlive, sans-serif',
        cofo: 'cofo, sans-serif',
    },
    colors: Object.assign({}, colors),
    variants: {
        blush: {
            primaryColor: colors.blush,
            secondaryColor: colors.pine,
        },
        pine: {
            primaryColor: colors.pine,
            secondaryColor: colors.blush,
        },
        pink: {
            primaryColor: colors.pink,
            secondaryColor: colors.light,
        },
        light: {
            primaryColor: colors.light,
            secondaryColor: colors.pine,
        },
    },
};
export default theme;
