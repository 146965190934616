const getEquivalents = async (params) => {
    //@ts-ignore
    const response = await fetch(`${__app.env.API_URL}/equivalents/co2/${params.equivalent}?kilosCo2=${params.kilosCo2}&language=${params.language}`, {
        headers: {
            //@ts-ignore
            'x-functions-key': __app.env.API_FUNCTION_KEY,
        },
    });
    const data = await response.json();
    return data;
};
export { getEquivalents };
