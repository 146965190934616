const breakpoints = {
    sm: '480px',
    md: '768px',
    lg: '992px',
    xl: '1280px',
    xxl: '1470px',
    xxxl: '1600px',
};
const mq = {
    sm: `(min-width: ${breakpoints.sm})`,
    md: `(min-width: ${breakpoints.md})`,
    mdMax: `(max-width: ${parseInt(breakpoints.md) - 1}px)`,
    lg: `(min-width: ${breakpoints.lg})`,
    lgMinMax: `(min-width: ${breakpoints.lg}) and (max-width: ${parseInt(breakpoints.xl) - 1}px)`,
    xl: `(min-width: ${breakpoints.xl})`,
    xlMinMax: `(min-width: ${breakpoints.xl}) and (max-width: ${parseInt(breakpoints.xxl) - 1}px)`,
    xxl: `(min-width: ${breakpoints.xxl})`,
    xxxl: `(min-width: ${breakpoints.xxxl})`,
};
export { mq, breakpoints };
export default breakpoints;
