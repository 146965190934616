<script lang="ts">
  import ChoooseLogo from './ChoooseLogo.svelte';
  import themeVars from '../styles/theme';
  import MediaQuery from './MediaQuery.svelte';
  import { mq } from '../styles/breakpoints';
  import Arrow from './Arrow.svelte';
  import { roundToUnit } from '../utils/numbers';
  import AnimatedNumbers from './AnimatedNumbers.svelte';
  type ThemeType = 'default' | 'corporate';

  export let impact: number;
  export let variantColor = 'blush';
  export let bgColor: string | undefined;
  export let fontColor: string | undefined;
  export let unit: string;
  export let choooseLabel: boolean;
  export let link = '';
  export let theme: ThemeType = 'default';

  const themeVariant = themeVars.variants[variantColor] ?? themeVars.variants.blush;
  const { primaryColor, secondaryColor } = themeVariant;
  const fontFamily = theme === 'corporate' ? themeVars.fonts.cofo : themeVars.fonts.antiqueOlive;
</script>

<MediaQuery query={mq.sm} let:matches>
  <div
    class={theme === 'corporate' ? 'container-corporate' : 'container'}
    style="background: {bgColor ?? primaryColor}; color: {fontColor ?? secondaryColor}; border: 2px solid {variantColor === 'light'
      ? bgColor ?? secondaryColor
      : 'transparent'}"
  >
    <div class="box {matches ? 'box-desktop' : 'box-mobile'}">
      <div class="inner-box">
        <span class={matches ? 'box__span-desktop' : 'box__span-mobile'} style="font-family: {fontFamily}">
          Climate impact
          {#if choooseLabel}
            {#if !matches}
              <br />
            {/if}
            with
            {#if matches}
              <br />
            {/if}
            <ChoooseLogo height={matches ? '30px' : '20px'} color={fontColor ?? secondaryColor} />
          {/if}
        </span>
        {#if link}
          <a
            href={link}
            class="box__button"
            target="_parent"
            rel="noopener noreferrer"
            style="font-family:{fontFamily}; color: {bgColor ?? primaryColor}; background-color: {fontColor ?? secondaryColor}"
          >
            <span class="box__button__span">Learn more</span>
            <Arrow marginRight="20px" color={bgColor ?? primaryColor} />
          </a>
        {/if}
      </div>

      <p class="box__impact {matches ? 'box__impact-desktop' : 'box__impact-mobile'}" style="font-family:{fontFamily}">
        <AnimatedNumbers impact={roundToUnit(unit, impact)} digitMargin={matches ? 18 : 14} />
      </p>
      <p class="box__unit" style="font-family:{fontFamily}">
        {unit} of
        {@html 'CO<sub>2</sub>e'}
        reduced
      </p>
    </div>
  </div>
</MediaQuery>

<style>
  .container {
    border-radius: 10px;
  }

  .box {
    display: flex;
    flex-direction: column;
    padding: 24px 30px;
  }

  .box-desktop {
    padding: 24px 30px;
  }
  .box-mobile {
    padding: 24px 10px;
  }

  .inner-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .box__span-desktop {
    font-size: 23px;
    line-height: 156%;
  }
  .box__span-mobile {
    font-size: 18px;
    line-height: 156%;
  }
  .box__button {
    font-size: 16px;
    height: 50px;
    width: 171px;
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: 35px;
    justify-content: space-between;
  }

  .box__button__span {
    padding-left: 25px;
  }

  .box__impact {
    font-weight: bold;
    font-size: 108px;
    margin: 0;
    line-height: 80%;
  }

  .box__impact-desktop {
    font-size: 92px;
    margin-top: 47px;
  }

  .box__impact-mobile {
    margin-top: 40px;
    font-size: 66px;
  }

  .box__unit {
    font-size: 18px;
    line-height: 23px;
    margin: 0;
    margin-top: 5px;
  }
</style>
