<script lang="ts">
  export let height: string;
  export let color: string;
</script>

<svg
  style="height: {height}; position: relative; top: 2px; fill: {color}"
  version="1.1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 1374 358"
  xml:space="preserve">
  <g>
    <path
      class="st0"
      d="M178.12,140.23v52.51h-1.74c-5.4-9.74-11.76-17.26-19.09-22.57c-11.19-8.1-23.48-12.15-36.89-12.15
      c-14.95,0-27.24,4.92-36.89,14.75c-10.99,11.09-16.49,27.68-16.49,49.76c0,21.6,4.68,38.72,14.03,51.35
      c4.15,5.5,9.62,9.82,16.42,12.95c6.8,3.13,14.2,4.7,22.21,4.7c23.91,0,42.81-11.77,56.7-35.3h1.74v46
      c-8.19,7.91-15.28,13.74-21.26,17.5c-13.79,8.39-29.32,12.58-46.58,12.58c-14.46,0-27.83-3.32-40.07-9.98
      c-17.45-9.36-30.14-24.69-38.04-46c-6.27-16.59-9.41-35.88-9.41-57.86c0-36.55,8.73-63.7,26.18-81.44
      c15.91-16.1,37.17-24.15,63.79-24.15c13.6,0,25.29,2.05,35.08,6.14C157.61,123.14,167.71,130.21,178.12,140.23z" />
    <path
      class="st0"
      d="M252.04,197.81h74.79v-80.27h44.7V327.7h-44.7v-85.49h-74.79v85.49h-44.84V117.53h44.84V197.81z" />
    <path
      class="st0"
      d="M462.62,109.08c29.72-10.82,54.53-10.61,74.42,0.62c19.27,10.94,34.88,32.82,46.82,65.62
      c4.42,12.14,7.59,24.79,9.5,37.95c1.91,13.16,2.24,24.48,0.98,33.97c-4.35,33.19-23.6,56-57.76,68.43
      c-25.28,9.21-47.93,8.57-67.95-1.89c-24.05-12.49-42.66-36.86-55.86-73.11c-12.26-33.71-14.21-61.74-5.82-84.08
      C414.98,135.51,433.54,119.67,462.62,109.08z
      M478.2,151.44c-14.13,5.15-23.26,13.91-27.37,26.28c-4.36,13.39-2.82,30.32,4.64,50.8
      c7.19,19.76,16.51,34.12,27.98,43.08c11.63,9.11,24.88,10.96,39.74,5.55c15.31-5.57,24.32-15.93,27.02-31.08
      c2.73-15.36,0.52-32.82-6.6-52.4C529.44,154.8,507.64,140.72,478.2,151.44z" />
    <path
      class="st0"
      d="M710.82,25.78c31.63,0,54.88,8.68,69.73,26.03c14.36,16.88,21.55,42.77,21.55,77.68
      c0,12.92-1.35,25.88-4.05,38.91c-2.7,13.02-6.26,23.77-10.7,32.26c-15.43,29.69-41.32,44.55-77.68,44.55
      c-26.9,0-47.97-8.34-63.21-25.03c-18.32-19.96-27.48-49.22-27.48-87.8c0-35.88,7.76-62.88,23.28-81.01
      C657.02,34.31,679.87,25.78,710.82,25.78z
      M710.97,70.9c-15.04,0-26.61,5.12-34.72,15.33c-8.68,11.09-13.02,27.54-13.02,49.33
      c0,21.02,3.86,37.7,11.57,50.04c7.82,12.54,19.63,18.81,35.44,18.81c16.3,0,28.31-6.65,36.03-19.97
      c7.81-13.5,11.71-30.66,11.71-51.49C757.98,91.59,742.31,70.9,710.97,70.9z" />
    <path
      class="st0"
      d="M958.99,109.23c29.72,10.82,48.6,26.93,56.61,48.32c7.73,20.77,5.63,47.56-6.31,80.37
      c-4.42,12.14-10.12,23.87-17.12,35.17c-6.99,11.31-14.02,20.2-21.09,26.65c-24.65,22.63-54.07,27.73-88.23,15.3
      c-25.28-9.2-42.23-24.25-50.84-45.13c-10.39-25.03-8.98-55.66,4.21-91.91c12.26-33.7,28.79-56.42,49.59-68.15
      C905.51,98.85,929.91,98.65,958.99,109.23z
      M943.69,151.7c-14.13-5.15-26.76-4.3-37.87,2.53c-11.94,7.45-21.65,21.42-29.1,41.9
      c-7.19,19.75-9.27,36.75-6.25,50.98c3.06,14.45,12.01,24.38,26.88,29.79c15.31,5.57,28.87,3.43,40.67-6.44
      c11.96-10.02,21.5-24.81,28.62-44.38C980.79,187.21,973.14,162.42,943.69,151.7z" />
    <path
      class="st0"
      d="M1178.86,132.86v49.9c-5.31-5.79-9.67-10.03-13.09-12.73c-3.43-2.7-7.6-5.16-12.51-7.38
      c-10.9-4.92-21.79-7.37-32.69-7.37c-9.55,0-17.23,2.17-23.07,6.5s-8.75,10.12-8.75,17.36c0,5.01,1.3,8.87,3.9,11.57
      c2.41,2.5,5.57,4.53,9.48,6.07c3.9,1.55,11.74,3.86,23.5,6.94c20.44,5.4,34.95,12.1,43.54,20.11
      c11.28,10.6,16.93,24.59,16.93,41.94c0,13.12-3.57,25.12-10.7,36.02c-6.65,10.22-15.34,17.84-26.04,22.86
      c-10.89,5.11-22.76,7.66-35.58,7.66c-11.96,0-22.88-1.69-32.77-5.06c-9.88-3.38-20.03-8.88-30.45-16.5v-45.99
      c9.45,10.02,18.13,17.02,26.04,20.98c11.28,5.59,22.9,8.39,34.86,8.39c11.57,0,20.2-2.84,25.89-8.54c4.34-4.24,6.5-9.5,6.5-15.77
      c0-7.61-3.32-13.6-9.98-17.93c-4.53-2.98-13.07-6.02-25.6-9.12c-23.82-5.88-40.36-13.64-49.62-23.28
      c-10.12-10.61-15.18-24.02-15.18-40.22c0-21.22,8.05-38.14,24.16-50.77c13.31-10.41,30.03-15.62,50.19-15.62
      C1139.62,112.9,1159.96,119.56,1178.86,132.86z" />
    <path
      class="st0"
      d="M1214.73,117.53H1352v42.09h-94.46v43.1H1352v39.93h-94.46v45.13H1352v39.92h-137.27V117.53z" />
  </g>
</svg>
