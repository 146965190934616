<script lang="ts">
  export let impact: number;
  export let digitMargin: number = 10;
  let height: number;
  let digitSpacesIdx: number[];
  let impactDigits: string[];
  const defaultDigits = [...Array(10).keys()];

  function getDigitSpaces(digits: string[]) {
    const spaces = [];
    for (let index = digits.length - 1; index >= 0; index--) {
      if (index % 3 === 0) {
        spaces.push(index);
      }
    }
    return spaces.map(n => digits.length - 1 - n);
  }

  $: {
    impactDigits = impact.toString().split('') as string[];
    digitSpacesIdx = getDigitSpaces(impactDigits);
  }
</script>

<style>
  .numbers-container {
    text-align: center;
    user-select: none;
    overflow: hidden;
    background: transparent;
    min-height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  :global(.digit) {
    display: flex;
    flex-direction: column;
    line-height: 1;
    text-align: center;
    align-self: flex-start;
    transition: transform 1s ease-in-out;
    will-change: transform;
  }

  :global(.number) {
    display: block;
  }

  :global(.digit.hidden) {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    opacity: 0;
  }
</style>

<div style="height: {height}px" class="numbers-container">
  {#if !!impact}
    <div class="digit hidden">
      <span class="number" aria-hidden="true" bind:clientHeight={height}>0</span>
    </div>
    {#each impactDigits as i, index}
      <div
        class="digit"
        style="transform: translate3d(0,-{Number(i) * height}px,0); margin-right: {digitSpacesIdx.includes(index) ? digitMargin : 0}px">
        {#each defaultDigits as digit}
          <span class="number">{digit}</span>
        {/each}
      </div>
    {/each}
  {:else}
    <div class="digit">
      <span class="number">0</span>
    </div>
  {/if}
</div>
