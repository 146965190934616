const getPartnershipImpact = async (partnershipId) => {
    //@ts-ignore
    const response = await fetch(`${__app.env.API_URL}/partnerships/${partnershipId}/kilosco2facilitated`);
    const data = await response.json();
    return data;
};
const getCustomerImpact = async (customerId) => {
    //@ts-ignore
    const response = await fetch(`${__app.env.API_URL}/customers/${customerId}/kilosco2paidfor`);
    const data = await response.json();
    return data;
};
export { getPartnershipImpact, getCustomerImpact };
