<script lang="ts">
  import { parse } from 'query-string';
  import Widget from './components/Widget.svelte';

  const notypecheck = (x: any) => x;
  const props = parse(location.search);
</script>

<main>
  <Widget {...notypecheck({ ...props })} />
</main>

<style>
  :global(html),
  :global(body) {
    margin: 0;
    padding: 0;
  }
  :global(body) {
    font-size: inherit;
  }
  :global(*),
  :global(*:before),
  :global(*:after) {
    box-sizing: border-box;
  }
</style>
