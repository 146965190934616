export function numberWithSpaces(num) {
    return num
        .toString()
        .split('')
        .reverse()
        .join('')
        .replace(/([0-9]{3})/g, '$1 ')
        .split('')
        .reverse()
        .join('');
}
export function roundToUnit(unit, impact) {
    if (unit === 'tonnes') {
        return Math.round(impact / 1000);
    }
    return Math.round(impact);
}
export function impactToBananas(impact) {
    const BANANA_PER_KG = 0.08;
    return Math.round(impact * BANANA_PER_KG);
}
