<script lang="ts">
  import WidgetStandard from './WidgetStandard.svelte';
  import WidgetSlim from './WidgetSlim.svelte';
  import WidgetLarge from './WidgetLarge.svelte';
  import { onMount } from 'svelte';
  import { impact, getPartnerImpact, getCustomerImpact } from '../stores/impact';
  import { parsedEquivalent, getEquivalents } from '../stores/equivalents';
  type VariantColor = 'blush' | 'pine' | 'pink' | 'light';
  type VariantType = 'slim' | 'standard' | 'large';
  type ThemeType = 'default' | 'corporate';

  export let variant: VariantType = 'standard';
  export let variantColor: VariantColor = 'blush';
  export let theme: ThemeType = 'default';
  export let bgColor: string | undefined;
  export let fontColor: string | undefined;
  export let partnershipId: string;
  export let customerId: string;
  export let unit = 'kg';
  export let link: string = '';
  export let choooseLabel: string;
  export let equivalent: string = 'avocado';

  const isHexColor = (hex: string) => (hex.length === 6 || hex.length === 3) && !isNaN(Number('0x' + hex));

  const parsedBgColor = bgColor ? (isHexColor(bgColor) ? `#${bgColor}` : bgColor) : undefined;
  const parsedFontColor = fontColor ? (isHexColor(fontColor) ? `#${fontColor}` : fontColor) : undefined;

  let canUseChoooseLabel = choooseLabel !== 'false';
  const INTERVAL = 60 * 1000; // 1 min

  const requesterId = customerId ? customerId : partnershipId;
  const getImpact = customerId ? getCustomerImpact : getPartnerImpact;

  onMount(async () => {
    await getImpact(requesterId);
    await getEquivalents($impact, equivalent);

    const idx = setInterval(async () => {
      await getImpact(requesterId);
      await getEquivalents($impact, equivalent);
    }, INTERVAL);
    return () => clearInterval(idx);
  });
</script>

{#if variant === 'slim'}
  <WidgetSlim {variantColor} {theme} bgColor={parsedBgColor} fontColor={parsedFontColor} {unit} choooseLabel={canUseChoooseLabel} impact={$impact} />
{:else if variant === 'large'}
  <WidgetLarge
    {variantColor}
    {theme}
    bgColor={parsedBgColor}
    fontColor={parsedFontColor}
    {unit}
    choooseLabel={canUseChoooseLabel}
    impact={$impact}
    equivalent={$parsedEquivalent}
  />
{:else}
  <WidgetStandard
    {variantColor}
    {theme}
    bgColor={parsedBgColor}
    fontColor={parsedFontColor}
    {unit}
    {link}
    choooseLabel={canUseChoooseLabel}
    impact={$impact}
  />
{/if}
