<script lang="ts">
  import ChoooseLogo from './ChoooseLogo.svelte';
  import themeVars from '../styles/theme';
  import MediaQuery from './MediaQuery.svelte';
  import { mq } from '../styles/breakpoints';
  import { roundToUnit, impactToBananas, numberWithSpaces } from '../utils/numbers';
  import AnimatedNumbers from './AnimatedNumbers.svelte';
  type ThemeType = 'default' | 'corporate';

  export let variantColor = 'blush';
  export let bgColor: string | undefined;
  export let fontColor: string | undefined;
  export let impact: number;
  export let unit: string;
  export let choooseLabel: boolean;
  export let equivalent: string;
  export let theme: ThemeType = 'default';

  const themeVariant = themeVars.variants[variantColor] ?? themeVars.variants.blush;
  const { primaryColor, secondaryColor } = themeVariant;
  const fontFamily = theme === 'corporate' ? themeVars.fonts.cofo : themeVars.fonts.antiqueOlive;
</script>

<MediaQuery query={mq.md} let:matches>
  <div
    class="container {matches ? (theme === 'corporate' ? 'container-desktop-corporate' : 'container-desktop') : 'container-mobile'}"
    style="background: {bgColor ?? primaryColor}; color: {fontColor ?? secondaryColor}; border: 2px solid {variantColor === 'light'
      ? fontColor ?? secondaryColor
      : 'transparent'}"
  >
    <div class="box {matches ? 'box-desktop' : 'box-mobile'}">
      <div class="header">
        <span
          class="header__title {matches ? 'header__title-desktop' : 'header__title-mobile'}"
          style="font-family: {fontFamily}; background: {bgColor ?? primaryColor}"
        >
          <span>
            Climate impact
            {#if choooseLabel}with{/if}
          </span>
          {#if choooseLabel}
            <ChoooseLogo height={matches ? '33px' : '20px'} color={fontColor ?? secondaryColor} />
          {/if}
        </span>
      </div>
      <div class="impact {matches ? 'impact-desktop' : 'impact-mobile'}">
        <div class="impact__numbers {matches ? 'impact__numbers-desktop' : 'impact__numbers-mobile'}" style="font-family: {fontFamily}">
          <AnimatedNumbers impact={roundToUnit(unit, impact)} digitMargin={matches ? 34 : 16} />
        </div>
        <div class="impact__footer">
          <div class="impact__text {matches ? 'impact__text-desktop' : 'impact__text-mobile'}" style="font-family: {fontFamily}">
            <span>
              {unit}
              {@html 'CO<sub>2</sub>e'}
            </span>
            <span>reduced</span>
          </div>
          <div
            class="impact__hint {matches ? 'impact__hint-desktop' : 'impact__hint-mobile'}"
            style="background: {fontColor ?? secondaryColor}; color: {bgColor
              ? bgColor
              : variantColor === 'pink'
              ? themeVars.colors.pine
              : primaryColor}; font-family: {fontFamily}"
          >
            <span class="impact__hint__arrow" style="color: {fontColor ?? secondaryColor}" />
            <span style="opacity: {variantColor === 'pink' && theme === 'default' ? 0.5 : 1}">That equals</span>
            <strong style="color: {bgColor ? bgColor : variantColor === 'blush' ? themeVars.colors.light : 'currentColor'}">
              {#if equivalent}{equivalent}{:else}{numberWithSpaces(impactToBananas(impact))} bananas{/if}
            </strong>
          </div>
        </div>
      </div>
    </div>
  </div>
</MediaQuery>

<style>
  .container {
    width: 100%;
  }

  .container-desktop {
    border-radius: 10px;
  }

  .box {
    display: flex;
    flex-direction: column;
  }

  .box-desktop {
    min-height: 378px;
    padding: 30px 0 42px 0;
  }

  .box-mobile {
    min-height: 250px;
    padding: 23px 0 20px 0;
  }

  .header {
    position: relative;
    width: 100%;
    text-align: center;
  }

  .header:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 2px;
    background-color: currentColor;
  }

  .header__title {
    display: inline-flex;
    flex-direction: row;
    text-align: center;
    margin: 0;
    justify-content: center;
    align-items: baseline;
    align-self: flex-start;
    position: relative;
  }

  .header__title > span {
    display: inline-block;
  }

  .header__title-desktop {
    font-size: 28px;
    line-height: 33px;
    padding: 0 40px;
  }

  .header__title-desktop > span {
    margin-right: 13px;
  }

  .header__title-mobile {
    font-size: 18px;
    line-height: 21px;
    padding: 0 10px;
  }

  .header__title-mobile > span {
    margin-right: 6px;
  }

  .impact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  .impact-desktop {
    margin-top: 50px;
  }

  .impact-mobile {
    margin-top: 36px;
  }

  .impact__numbers {
    font-weight: bold;
    white-space: nowrap;
    text-align: center;
    line-height: 1;
  }

  .impact__numbers-desktop {
    font-size: 152px;
  }
  .impact__numbers-mobile {
    font-size: 72px;
  }

  .impact__footer {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    margin-top: 10px;
  }

  .impact__text {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .impact__text-desktop {
    font-size: 24px;
    margin-right: 23px;
  }

  .impact__text-desktop > span:first-of-type {
    margin-right: 7px;
  }

  .impact__text-mobile {
    font-size: 18px;
    line-height: 22px;
    margin-right: 30px;
    flex-direction: column;
    align-items: flex-start;
  }

  .impact__hint {
    display: inline-flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 4px 17px;
    text-align: left;
    font-size: 16px;
    line-height: 135%;
    position: relative;
    max-width: 50%;
  }

  .impact__hint__arrow {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    left: -6px;
    top: calc(50% - 9px);
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid currentColor;
  }

  .impact__hint strong {
    font-weight: 500;
  }
</style>
