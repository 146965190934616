<script lang="ts">
  import ChoooseLogo from './ChoooseLogo.svelte';
  import themeVars from '../styles/theme';
  import MediaQuery from './MediaQuery.svelte';
  import { mq } from '../styles/breakpoints';
  import { roundToUnit } from '../utils/numbers';
  import AnimatedNumbers from './AnimatedNumbers.svelte';
  type ThemeType = 'default' | 'corporate';

  export let impact: number;
  export let variantColor = 'blush';
  export let bgColor: string | undefined;
  export let fontColor: string | undefined;
  export let unit: string;
  export let choooseLabel: boolean;
  export let theme: ThemeType = 'default';

  const themeVariant = themeVars.variants[variantColor] ?? themeVars.variants.blush;
  const { primaryColor, secondaryColor } = themeVariant;
  const fontFamily = theme === 'corporate' ? themeVars.fonts.cofo : themeVars.fonts.antiqueOlive;
</script>

<MediaQuery query={mq.md} let:matches>
  <div
    class="container"
    style="background: {bgColor ?? primaryColor}; color: {fontColor ?? secondaryColor}; border: 2px solid {variantColor === 'light'
      ? bgColor ?? secondaryColor
      : 'transparent'}"
  >
    <div class="box {matches ? 'box-desktop' : 'box-mobile'}">
      <span class="box__span-text {matches ? 'box__span-text-desktop' : 'box__span-text-mobile'}" style="font-family: {fontFamily}">
        Climate impact
        {#if choooseLabel}
          {#if matches}with{/if}
          <span>
            {#if !matches}with{/if}
            <ChoooseLogo height={matches ? '40px' : '20px'} color={fontColor ?? secondaryColor} />
          </span>
        {/if}
      </span>
      <div class="impact {matches ? 'desktop' : 'mobile'}">
        <span
          class="impact__span-numbers {matches ? 'impact__span-numbers-desktop' : 'impact__span-numbers-mobile'}"
          style="font-family: {fontFamily}"
        >
          <AnimatedNumbers impact={roundToUnit(unit, impact)} digitMargin={matches ? 14 : 10} />
        </span>
        <span
          class={matches ? 'impact__span-text-desktop' : 'impact__span-text-mobile'}
          style="font-family: {fontFamily}; opacity: {variantColor === 'pink' && theme === 'default' ? 0.5 : 1}"
        >
          {unit}
          {@html 'CO<sub>2</sub>e'}
          {#if matches}
            <br />
          {/if}
          reduced
        </span>
      </div>
    </div>
  </div>
</MediaQuery>

<style>
  .container {
    width: 100%;
  }
  .box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .box-desktop {
    padding: 17px 25px 17px 25px;
  }

  .box-mobile {
    padding: 17px 13px 17px 13px;
  }

  .box__span-text {
    line-height: 32px;
    display: inline-flex;
    align-items: center;
  }

  .box__span-text span {
    position: relative;
    display: inline-block;
    margin-left: 12px;
    top: -4px;
  }

  .box__span-text-desktop {
    font-size: 28px;
  }

  .box__span-text-mobile {
    font-size: 18px;
    flex-direction: column;
  }

  .box__span-text-mobile span {
    margin-left: 0;
    top: -2px;
  }

  .impact {
    display: flex;
    justify-content: flex-end;
  }

  .desktop {
    flex-direction: row;
    align-items: center;
  }

  .mobile {
    flex-direction: column;
    align-items: flex-start;
  }

  .impact__span-numbers {
    font-weight: bold;
    white-space: nowrap;
  }

  .impact__span-numbers-desktop {
    font-size: 53px;
  }
  .impact__span-numbers-mobile {
    font-size: 36px;
  }

  .impact__span-text-desktop {
    font-size: 20px;
    line-height: 23px;
    margin-left: 10px;
  }

  .impact__span-text-mobile {
    font-size: 18px;
    line-height: 23px;
  }
</style>
