import { writable } from 'svelte/store';
import * as impactApi from '../api/impact';
const impact = writable(0);
async function getPartnerImpact(partnershipId) {
    const data = await impactApi.getPartnershipImpact(partnershipId);
    impact.set(data);
    return data;
}
async function getCustomerImpact(customerId) {
    const data = await impactApi.getCustomerImpact(customerId);
    impact.set(data);
    return data;
}
export { impact, getPartnerImpact, getCustomerImpact };
